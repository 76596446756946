
































































































import Vue from 'vue';
// @ts-ignore
import LazyHydrate from 'vue-lazy-hydration';
import { PortalContainers } from '@devhacker/shared/plugins/portal';
import TopHeaderContainer from '~/containers/TopHeaderContainer/TopHeaderContainer.vue';
import TheFooterContainer from '~/containers/TheFooterContainer.amp';
import GoogleOneTapAuth from '~/components/GoogleOneTapAuth/GoogleOneTapAuth.vue';
import LoginWindow from '~/components/LoginWindow/LoginWindow.vue';
import SafariPushWindowContainer from '~/containers/SafariPushWindowContainer/SafariPushWindowContainer.vue';
import NavMenuContainer from '~/containers/NavMenuContainer/NavMenuContainer.vue';
import DarkBackgroundContainer from '~/containers/DarkBackgroundContainer.vue';
import PrimaryMenu from '~/components/PrimaryMenu.vue';
import NavMenuBelowHeaderContainer from '~/containers/NavMenuBelowHeaderContainer';
import WidgetsRendererContainer from '~/containers/WidgetsRendererContainer.vue';
import ContentWrapperForBranding from '~/components/ContentWrapperForBranding.vue';
import FilterContainer from '~/containers/FilterContainer/FilterContainer.vue';
import FilterOpenButton from '~/components/filter/FilterOpenButton/FilterOpenButton.vue';
import BeforePrintMixin from '~/mixins/BeforePrintMixin';
import SmartBannerMixin from '~/mixins/SmartBannerMixin';
import TheBadges from '~/components/Pusher/TheBadges/TheBadges.vue';

import type { Menus } from '~/store/menus';
import { isTheRecipePage, isRecipePreviewPage } from '~/router';
import type { AdfoxParams, AdfoxParamsWithIp } from '~/store/adfoxParams';
import {
  isRetina,
  lowZindexTopHeaderContainer,
  highZindexTopHeaderContainer,
} from '~/utils';
import PageScroll from '~/libs/PageScroll';
import getSlugFromUrl from '~/utils/getSlugFromUrl';

const TypoFormContainer = () =>
  import('~/containers/TypoFormContainer' /* webpackChunkName: 'TypoFormContainer' */);

export default Vue.extend({
  name: 'RecipesLayout',

  components: {
    PortalContainers,
    LazyHydrate,
    TopHeaderContainer,
    TheFooterContainer,
    LoginWindow,
    SafariPushWindowContainer,
    NavMenuContainer,
    DarkBackgroundContainer,
    PrimaryMenu,
    NavMenuBelowHeaderContainer,
    WidgetsRendererContainer,
    ContentWrapperForBranding,
    FilterContainer,
    FilterOpenButton,
    TypoFormContainer,
    GoogleOneTapAuth,
    TheBadges,
  },

  mixins: [BeforePrintMixin, SmartBannerMixin],

  data () {
    return {
      openedFilter: false as boolean,
      // analytics
      filterUsedEventForCurrentPageSent: false as boolean,
      // See https://app.clickup.com/t/18mthq3
      scrollDown: false as boolean,

      filterCount: 0 as number,
    };
  },

  computed: {
    filterButtonClass (): any {
      return {
        // @ts-ignore
        'recipes-layout__filter-button--smart-banner': this.mixin__smartBanner__isSmartBanner,
      };
    },
    recipesLayoutContentClass (): any {
      return {
        'recipes-layout__content--up': this.openedFilter,
      };
    },
    primaryNavMenuItems (): Array<MenuItem> {
      return (this.$store.getters.menus as Menus)?.projects?.items ?? [];
    },
    widgetsAboveHeader (): TWidget[] {
      return this.$store.getters.currentAboveRecipeHeader;
    },
    widgetsBelowHeader (): TWidget[] {
      return this.$store.getters.currentBelowRecipeHeader;
    },
    isShowFilterButton (): boolean {
      // return !this.openedFilter;
      return (!this.openedFilter && !this.scrollDown);
    },
    rightSidebarWidgets (): TWidget[] {
      // на внутренней странице рецепта
      if (isTheRecipePage(this.$route.name)) {
        return this.$store.getters.currentRecipeSidebar;
      }
      // на архивной странице
      return this.$store.getters.currentRecipesSidebar;
    },
    isPreview (): boolean {
      return isRecipePreviewPage(this.$route.name);
    },
    adfoxParams (): AdfoxParamsWithIp {
      return this.$store.getters.currentAdfox;
    },
    isShowTypoForm (): boolean {
      return this.$store.state.typos.isShowForm;
    },

    classCounterMore (): string {
      return this.filterCount > 9 ? 'counter--more' : '';
    },
    counterText (): string {
      return this.filterCount > 9 ? '9+' : this.filterCount + '';
    },
  },

  watch: {
    $route (): void {
      this.filterUsedEventForCurrentPageSent = false;
    },
    adfoxParams (val: AdfoxParams): void {
      this.setAdfoxParamsInWindow(val);
    },
    // TODO: переделать, не трогая шапку
    // править z-index контента рецепта
    isShowTypoForm (isShow: boolean): void {
      if (isShow) {
        lowZindexTopHeaderContainer();
      } else {
        highZindexTopHeaderContainer();
      }
    },
  },

  mounted (): void {
    this.setAdfoxParamsInWindow(this.adfoxParams);

    this.$nextTick(() => {
      if (!(this.$refs.shutter && this.$refs.filter)) {
        return;
      }
      this.touchCloseHandler(
        this.$refs.shutter as HTMLElement,
        this.$refs.filter as HTMLElement,
      );

      document.addEventListener('click', (e) => {
        // @ts-ignore
        const withinBoundaries = e.composedPath().includes(this.$refs.filter);

        if (!withinBoundaries && this.openedFilter) {
          this.setOpenedFilter(false);
        }
      });
    });
  },

  methods: {
    touchCloseHandler (handlerElement: HTMLElement, moveElement: HTMLElement) {
      let startY: number;
      let rafId: number;
      let deltaY: number = 0;
      let moving = false;

      const pointerDown = ({ clientY, isPrimary }: PointerEvent) => {
        if (!isPrimary) { return; }
        startY = clientY;
        deltaY = 0;
        moveElement.style.transition = '';
        handlerElement.addEventListener('pointermove', pointerMove);
        handlerElement.addEventListener('pointerup', pointerUp);
        handlerElement.addEventListener('pointercancel', pointerUp);
      };

      const pointerMove = ({ clientY, isPrimary }: PointerEvent) => {
        if (!isPrimary) { return; }
        deltaY = Math.max(clientY - startY, 0);
        if (!moving) {
          moving = true;
          rafId = requestAnimationFrame(() => {
            moving = false;
            moveElement.style.transform = `translateY(${deltaY}px)`;
          });
        }
      };

      const pointerUp = ({ isPrimary }: PointerEvent) => {
        if (!isPrimary) { return; }
        moving = false;
        handlerElement.removeEventListener('pointermove', pointerMove);
        handlerElement.removeEventListener('pointerup', pointerUp);
        handlerElement.removeEventListener('pointercancel', pointerUp);
        cancelAnimationFrame(rafId);
        requestAnimationFrame(() => {
          moveElement.style.transform = '';
          moveElement.style.transition = 'transform 250ms';
          if (deltaY > 100) {
            this.setOpenedFilter(false);
          }
        });
      };

      handlerElement.addEventListener('pointerdown', pointerDown);
    },
    setOpenedFilter (val: boolean, sendAnalyticsEvent: boolean = true): void {
      this.openedFilter = val;
      this.onFilterUsed();
      sendAnalyticsEvent && this.sendFilterOpenedAnalytics(val);

      if (val) {
        PageScroll.disable(null, false);
      } else {
        const filterEl = document.querySelector('#filters');
        const callbackfn = (el: Element) => { el.scrollLeft = 0; };
        filterEl?.querySelectorAll('.container__first').forEach(callbackfn);
        filterEl?.querySelectorAll('.container__last').forEach(callbackfn);
        filterEl?.querySelectorAll('.container__once').forEach(callbackfn);
        PageScroll.enable(null, false);
      }
    },
    sendFilterOpenedAnalytics (isOpened: boolean): void {
      this.$sendAnalyticsEvent({
        event: 'Фильтры_появление_мобила',
        slug_location: getSlugFromUrl(this.$route.fullPath),
        slug_referrer: '',
        element: '',
        item: isOpened ? 'Раскрытие' : 'Сворачивание',
        action: '',
        value: 1,
        currency: 'piece',
      });
    },
    onFilterUsed (): void {
      if (this.filterUsedEventForCurrentPageSent) {
        return;
      }
      this.filterUsedEventForCurrentPageSent = true;
    },
    setAdfoxParamsInWindow (val: AdfoxParams): void {
      Object.assign(
        window,
        {
          ...val,
          lh_adfox_rtn: isRetina(),
        },
      );
    },
    setCount (payload: number) {
      this.filterCount = payload;
    },
  },
});
